import React from 'react'
import clsx from 'clsx'
import useFormatDate from '../hooks/useFormatDate'

const STOP_SIZE = 8

const FlightOverviewTimingMobile = ({
    stops,
    arrivalTime,
    departureTime,
}) => {
    const format = useFormatDate()

    return (
        <div className="flex gap-x-2">
            <div className="py-1.5">
                <div
                    style={{ width: STOP_SIZE }}
                    className="relative self-center h-full"
                >
                    <div
                        style={{ left: (STOP_SIZE / 2) - 0.5 }}
                        className="absolute h-full w-px bg-gray-200"
                    />
                    <div className="absolute h-full flex flex-col justify-between">
                        {Array.from(Array(stops + 2).keys()).map((_, index) => (
                            <div
                                style={{
                                    width: STOP_SIZE,
                                    height: STOP_SIZE,
                                }}
                                // eslint-disable-next-line react/no-array-index-key
                                key={`stop-${index}`}
                                className={clsx(
                                    'rounded',
                                    index === 0 || index + 1 === stops + 2 ? 'bg-blue-bright' : 'bg-gray-200'
                                )}
                            />
                        ))}
                    </div>
                </div>
            </div>
            <div className="flex flex-col justify-between mx-4text-base font-medium">
                <p data-testid="FlightOverviewTimingMobileDeparture">
                    {format(new Date(departureTime), 'HH:mm')}
                </p>
                <p data-testid="FlightOverviewTimingMobileArrival">
                    {format(new Date(arrivalTime), 'HH:mm')}
                </p>
            </div>
        </div>
    )
}

export default FlightOverviewTimingMobile
