import React from 'react'
import useFlightProps from '../hooks/useFlightProps'
import SegmentDetailsMobile from './SegmentDetailsMobile'
import SegmentDetailsDesktop from './SegmentDetailsDesktop'

const SegmentDetails = ({
    segment,
    baggageAllowed,
}) => {
    const { getFlightPropsFromSegment } = useFlightProps()
    const props = getFlightPropsFromSegment(segment)

    return (
        <>
            <SegmentDetailsMobile {...props} baggageAllowed={baggageAllowed} />
            <SegmentDetailsDesktop {...props} baggageAllowed={baggageAllowed} />
        </>
    )
}

export default SegmentDetails
