import React from 'react'
import clsx from 'clsx'
import { Trans } from '@lingui/react'
import useFormatDate from '../hooks/useFormatDate'
import Icon from './Icon'
import Modal from './Modal'
import SegmentDetails from './SegmentDetails'
import SegmentLayoverDivider from './SegmentLayoverDivider'

const FlightDetailsModal = ({
    testId,
    onClose,
    segments,
    className,
    departureTime,
    baggageAllowed,
}) => {
    const format = useFormatDate()

    return (
        <Modal
            onClose={onClose}
            data-testid={testId}
            className={clsx(
                'px-4 py-10 w-full self-center min-h-screen bg-gray-50',
                className,
            )}
        >
            <div className="flex justify-between items-center mb-4">
                <h1 className="text-sm text-gray-600">
                    <Trans id="Flight details" />
                    {' '}
                    <span className="font-bold">
                        {format(new Date(departureTime), 'EE, d MMM yyyy').toUpperCase()}
                    </span>
                </h1>
                <button
                    type="button"
                    onClick={onClose}
                >
                    <Icon
                        name="cross"
                        color="blue"
                        size="smaller"
                    />
                </button>
            </div>
            {segments.map((segment, index) => {
                const nextSegment = segments[index + 1]

                return (
                    <>
                        <div
                            // eslint-disable-next-line react/no-array-index-key
                            key={`${segment.id}/${index}`}
                            className="rounded border border-gray-100 bg-gray-50"
                        >
                            <SegmentDetails
                                segment={segment}
                                baggageAllowed={baggageAllowed}
                            />
                        </div>
                        {nextSegment && (
                            <SegmentLayoverDivider
                                arrivalTime={segment.arrivalTime}
                                departureTime={nextSegment.departureTime}
                            />
                        )}
                    </>
                )
            })}
        </Modal>
    )
}

export default FlightDetailsModal
